import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./app.css";
import { installCommonHeadersForBffServicesInterceptor } from "./common/addCommonBftHeadersInterceptor";

if (!Number.isNaN) {
  /**
   * isNaN polyfill for IE11. Oddly not provided by react-app-polyfill
   */
  Number.isNaN = function (o) {
    return typeof o === "number" && isNaN(o);
  };
}

const ConnectedApp = React.lazy(() => {
  return import("./App");
});

function Init() {
  return (
    <React.Suspense fallback={null}>
      <ConnectedApp />
    </React.Suspense>
  );
}

if (module.hot) {
  module.hot.accept(render);
}

function render() {
  const rootElement = document.getElementById("root");
  ReactDOM.render(<Init />, rootElement);
}

installCommonHeadersForBffServicesInterceptor()

render();

serviceWorker.unregister();
